import { NgModule,Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoaderComponent } from './loader.component';
@NgModule({
    imports: [
       
    ],
    declarations: [
        LoaderComponent
    ],
    providers:
        [
        ],
    exports: [
        LoaderComponent
    ]
})
export class LoaderModule { }
